import { useState } from "react"
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faCode,
  faH1,
  faH2,
  faListOl,
  faListUl,
  faQuoteRight,
  faSearch,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faRectangleCode,
} from "@fortawesome/pro-solid-svg-icons"
import { useSlate } from "slate-react"
import { Editor } from "slate"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Toolbar, Button } from "./components"
import { InsertImageButton, InsertAttachmentButton } from "./plugins/withImages"
import { InsertTableButton } from "./plugins/withTables"
import { useTable } from "./hooks"
import { isBlockActive, TEXT_ALIGN_TYPES, toggleBlock } from "./utils"
import { StyledInput } from "./styled"
import OutsideClickAlerter from "../OutsideClickAlerter"
import { Flex } from "js/includes/components/Styled"

const isFormatActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks?.[format] === true
}

const toggleFormat = (editor, format) => {
  const isActive = isFormatActive(editor, format)
  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const FormatButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isFormatActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleFormat(editor, format)
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  )
}

const BlockButton = ({ format, icon, disabled }) => {
  const editor = useSlate()
  return (
    <Button
      disabled={disabled}
      active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type")}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  )
}

const SearchInput = ({ search, setSearch, disabled }) => {
  const [showSearchBar, setShowSearchBar] = useState(false)
  return showSearchBar ? (
    <OutsideClickAlerter
      display="flex"
      handleClickOutside={() => {
        if (!search) {
          setShowSearchBar(false)
        }
      }}
    >
      <StyledInput autoFocus value={search} onChange={e => setSearch(e.target.value)} />
    </OutsideClickAlerter>
  ) : (
    <Button
      disabled={disabled}
      onClick={event => {
        event.preventDefault()
        setShowSearchBar(true)
      }}
    >
      <FontAwesomeIcon icon={faSearch} />
    </Button>
  )
}

const DefaultToolbar = ({ addInlineImage, addAttachment, setSearch, search, allowInlineImages, allowAttachments }) => {
  const editor = useSlate()
  const isTable = useTable(editor)

  return (
    <Toolbar>
      <Flex flex={1} flexWrap="wrap">
        <FormatButton format="bold" icon={faBold} />
        <FormatButton format="italic" icon={faItalic} />
        <FormatButton format="underline" icon={faUnderline} />
        <FormatButton format="strikethrough" icon={faStrikethrough} />
        <FormatButton format="code" icon={faCode} />

        <BlockButton format="block-code" icon={faRectangleCode} disabled={isTable} />
        <BlockButton format="heading-one" icon={faH1} disabled={isTable} />
        <BlockButton format="heading-two" icon={faH2} disabled={isTable} />
        <BlockButton format="block-quote" icon={faQuoteRight} disabled={isTable} />
        <BlockButton format="numbered-list" icon={faListOl} disabled={isTable} />
        <BlockButton format="bulleted-list" icon={faListUl} disabled={isTable} />
        <BlockButton format="left" icon={faAlignLeft} />
        <BlockButton format="center" icon={faAlignCenter} />
        <BlockButton format="right" icon={faAlignRight} />
        <BlockButton format="justify" icon={faAlignJustify} disabled={isTable} />

        {allowInlineImages && <InsertImageButton disabled={isTable} {...{ addInlineImage }} />}
        {allowAttachments && <InsertAttachmentButton disabled={isTable} {...{ addAttachment }} />}
        <InsertTableButton disabled={isTable} />
      </Flex>

      <Flex>
        <SearchInput {...{ setSearch, search }} disabled={isTable} />
      </Flex>
    </Toolbar>
  )
}

export default DefaultToolbar
