import React, { memo } from "react"
import styled from "@emotion/styled"
import { faDownload, faTimes, faInfoCircle, faFileExclamation } from "@fortawesome/pro-solid-svg-icons"
import { cond, always, flatten, T, includes, equals } from "ramda"
import { Box, StyledFontAwesomeIcon } from "js/includes/components/Styled"
import { StyledLabel, StyledButton } from "js/includes/components/Styled/Form"
import { getIconProps } from "js/includes/common/utils"
import { colors } from "js/includes/common/theme"
import { contains, downloadFile, reportErrorAndShowMessage, localized } from "js/includes/common/utils"
import Tooltip from "js/includes/components/Tooltip"
import {
  IMAGE_EXTENSIONS,
  WORD_EXTENSIONS,
  EXCEL_EXTENSIONS,
  POWER_POINT_EXTENSIONS,
  VISIO_EXTENSIONS,
  MISCELLANEOUS_EXTENSIONS,
  WEB_EXTENSIONS,
  COMPRESSED_FILE_EXTENSIONS,
  ATTACHMENT_FILE_EXTENSIONS,
} from "js/includes/common/utils"
import { AWSResourceImage } from "js/includes/ticketing/editor/shared/components"
import Indicator from "js/includes/components/Indicator"
import { getSignedUrlFromAttachmentId, getSignedUrlFromContentId } from "js/includes/common/client"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const StyledAttachment = styled(Box)`
  width: 100%;
  height: 100px;
  border: 1px solid ${colors.ninjaLight};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${({ opacity }) => opacity};
  position: relative;
  overflow: hidden;

  .icon-button {
    display: none;
  }

  &:hover {
    .icon-button {
      display: flex;
    }
  }
`

const DocumentDisplay = memo(({ name, size, extension }) => (
  <Box
    padding={1}
    display="flex"
    flexDirection="column"
    flex={1}
    justifyContent="center"
    alignItems="center"
    width="100%"
    overflow="hidden"
  >
    <Box>
      <StyledFontAwesomeIcon {...getIconProps(extension, "20px")} />
    </Box>

    <Box className="text-ellipsis" marginTop={2}>
      <Tooltip output={name}>
        <StyledLabel fontWeight="bold" display="inline">
          {name}
        </StyledLabel>
      </Tooltip>
    </Box>

    <Box display="flex">
      <StyledLabel color="ninjaMedium">{`${extension?.toUpperCase()} - ${size}`}</StyledLabel>
    </Box>
  </Box>
))

const Preview = memo(({ attachmentId, contentId, uploadStatus, tempUri, mimeType, size, name, extension }) =>
  cond([
    [
      contains(IMAGE_EXTENSIONS),
      always(
        <AWSResourceImage
          {...{
            key: Date.now(),
            maxHeight: 100,
            uploadStatus,
            attachmentId,
            contentId,
            tempUri,
          }}
        />,
      ),
    ],
    [
      contains(
        flatten([
          WORD_EXTENSIONS,
          EXCEL_EXTENSIONS,
          POWER_POINT_EXTENSIONS,
          VISIO_EXTENSIONS,
          WEB_EXTENSIONS,
          COMPRESSED_FILE_EXTENSIONS,
          MISCELLANEOUS_EXTENSIONS,
        ]),
      ),
      always(
        <DocumentDisplay
          {...{
            mimeType,
            size,
            name,
            extension,
          }}
        />,
      ),
    ],
    [T, null],
  ])(extension.toLowerCase()),
)

const ErrorStatus = ({ icon, token = localizationKey("There was an error while uploading the attachment") }) => (
  <Tooltip output={localized(token)}>
    <StyledFontAwesomeIcon fontSize={7} icon={icon} marginRight={1} color="ninjaRed" />
  </Tooltip>
)

export const renderAttachment = cond([
  [({ uploadStatus }) => equals(uploadStatus, "PROCESSING"), always(<Indicator noText />)],
  [({ uploadStatus }) => equals(uploadStatus, "FAILURE"), always(<ErrorStatus icon={faInfoCircle} />)],
  [
    ({ uploadStatus }) => equals(uploadStatus, "SUSPICIOUS"),
    always(
      <ErrorStatus
        icon={faFileExclamation}
        token={localizationKey("This attachment has been flagged as suspicious")}
      />,
    ),
  ],
  [T, props => <Preview {...props} />],
])

export const AttachmentPreview = memo(({ attachment, onDelete, download, ...rest }) => {
  const { id: attachmentId, contentId, tempUri, progress, metadata, uploadStatus } = attachment
  const { mimeType, size, name, extension } = metadata

  const isUploading = progress < 100
  const isValidExtension = includes(`.${extension}`, ATTACHMENT_FILE_EXTENSIONS)

  return isValidExtension ? (
    <StyledAttachment opacity={!isUploading ? 1 : 0.5} backgroundColor="white" {...rest}>
      {isUploading && (
        <Box
          top={0}
          left={0}
          height={5}
          right={`${100 - progress}%`}
          backgroundColor="ninjaGreen"
          position="absolute"
        />
      )}

      <Box top={5} right={5} className="icon-button" position="absolute">
        {!isUploading && !!download && uploadStatus === "SUCCESS" && (
          <StyledButton
            width={20}
            height={20}
            padding={2}
            borderWidth="1px"
            borderStyle="solid"
            borderColor="ninjaLight"
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="ninjaWhite"
            onClick={async e => {
              e.preventDefault()
              e.stopPropagation()

              try {
                const { signedUrl } = contentId
                  ? await getSignedUrlFromContentId(contentId)
                  : await getSignedUrlFromAttachmentId(attachmentId)
                downloadFile(signedUrl, name)
              } catch (error) {
                reportErrorAndShowMessage(error)
              }
            }}
          >
            <StyledFontAwesomeIcon size="sm" icon={faDownload} />
          </StyledButton>
        )}

        {!isUploading && !!onDelete && (
          <StyledButton
            width={20}
            height={20}
            padding={2}
            marginLeft={1}
            borderWidth="1px"
            borderStyle="solid"
            borderColor="ninjaLight"
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="ninjaWhite"
            hoverTextColor="ninjaRed"
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              onDelete?.(attachment)
            }}
          >
            <StyledFontAwesomeIcon size="sm" icon={faTimes} />
          </StyledButton>
        )}
      </Box>

      {renderAttachment({
        tempUri,
        mimeType,
        attachmentId,
        contentId,
        size,
        name,
        extension,
        uploadStatus,
      })}
    </StyledAttachment>
  ) : (
    <StyledLabel>{localized("File is not supported.")}</StyledLabel>
  )
})
