import { css } from "@emotion/css"
import isHotkey from "is-hotkey"
import { useCallback, useMemo, useState } from "react"
import { Editor, Element as SlateElement, Text, Transforms } from "slate"
import { Editable, Slate } from "slate-react"

import { getLanguageDir, localized } from "js/includes/common/utils"

import DefaultToolbar from "./DefaultToolbar"
import HoveringToolbar from "./HoveringToolbar"
import { Code, EditorContainer, Section } from "./components"
import { useEditor } from "./hooks/useEditor"
import { Image } from "./plugins/withImages"
import { EditableButtonComponent, LinkComponent } from "./plugins/withInlines"
import { TableComponent } from "./plugins/withTables"
import { serializeToHTML, serializeToPlainText } from "./serializer"
import { StyledEm } from "./styled"
import { LIST_TYPES, getEmptyBlock, insertDefaultBlock, toggleBlock } from "./utils"

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+s": "strikethrough",
  "mod+h`": "code",
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const matchBlockElements = (editor, types) =>
  Editor.nodes(editor, {
    match: n => {
      const isEditorAndElement = !Editor.isEditor(n) && SlateElement.isElement(n)
      return types.some(type => isEditorAndElement && n.type === type)
    },
  })

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Element = props => {
  const { attributes, children, element, customElementRenderers = {} } = props

  if (element.type in customElementRenderers) return customElementRenderers[element.type](props)

  const isRtl = getLanguageDir() === "rtl"
  const style = {
    textAlign: element.align || (isRtl ? "right" : "left"),
  }

  switch (element.type) {
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case "block-code":
      return (
        <Code style={style} {...attributes}>
          {children}
        </Code>
      )
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case "image":
      return <Image {...props} />
    case "table":
      return <TableComponent {...props} />
    case "table-body":
      return <tbody {...attributes}>{children}</tbody>
    case "table-row":
      return <tr {...attributes}>{children}</tr>
    case "table-cell":
      return (
        <td style={style} {...attributes}>
          {children}
        </td>
      )
    case "link":
      return <LinkComponent {...props} />
    case "button":
      return <EditableButtonComponent {...props} />
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = (
      <code
        className={css`
          white-space: normal;
        `}
      >
        {children}
      </code>
    )
  }

  if (leaf.italic) {
    children = <StyledEm>{children}</StyledEm>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>
  }

  return (
    <span
      {...attributes}
      {...(leaf.highlight && { "data-cy": "search-highlighted" })}
      // The following is a workaround for a Chromium bug where,
      // if you have an inline at the end of a block,
      // clicking the end of a block puts the cursor inside the inline
      // instead of inside the final {text: ''} node
      // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
      className={css`          
          ${leaf.text === "" ? "padding-left: 0.1px;" : ""}
          background-color: ${leaf.highlight ? "#ffeeba !important" : "inherit"};
        `}
    >
      {children}
    </span>
  )
}

const preventDragHandler = e => {
  e.preventDefault()
}

const RichTextEditor = ({
  editor,
  initialValue,
  readOnly,
  attachments,
  techniciansTagged,
  onKeyDown,
  onChange,
  onBlur,
  onDragStart = preventDragHandler,
  addInlineImage,
  addAttachment,
  customPortalRenderer,
  customElementRenderers,
  customSerializeToHtml,
  placeholder = localized("general.enterSomeRichText"),
  allowLinks = true,
  allowInlineImages = true,
  allowAttachments = true,
  hasError = false,
}) => {
  const [search, setSearch] = useState()

  const languageDir = useMemo(() => getLanguageDir(), [])

  const renderElement = useCallback(
    props => <Element {...props} {...{ readOnly, attachments, techniciansTagged, customElementRenderers }} />,
    [readOnly, attachments, techniciansTagged, customElementRenderers],
  )
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const [innerEditor] = useEditor({ editor, addInlineImage })

  const decorate = useCallback(
    ([node, path]) => {
      const ranges = []

      if (search && Text.isText(node)) {
        const { text } = node
        const parts = text.toLowerCase().split(search.toLowerCase())
        let offset = 0

        parts.forEach((part, i) => {
          if (i !== 0) {
            ranges.push({
              anchor: { path, offset: offset - search.length },
              focus: { path, offset },
              highlight: true,
            })
          }

          offset = offset + part.length + search.length
        })
      }

      return ranges
    },
    [search],
  )

  return (
    <Slate
      editor={innerEditor}
      value={initialValue ?? [getEmptyBlock()]}
      onChange={value => {
        if (readOnly) return

        onChange({
          editor: innerEditor,
          value,
          text: serializeToPlainText(value),
          html: serializeToHTML(innerEditor, customSerializeToHtml),
        })
      }}
    >
      <Section width="100%" borderRadius="3px" flexDirection="column" readOnly={readOnly} hasError={hasError}>
        {!readOnly && (
          <DefaultToolbar
            width="100%"
            {...{ addInlineImage, addAttachment, setSearch, search, allowInlineImages, allowAttachments }}
          />
        )}

        <EditorContainer padding={readOnly ? 0 : "20px 8px"}>
          {!readOnly && <HoveringToolbar {...{ allowLinks }} />}

          <Editable
            spellCheck
            readOnly={readOnly}
            decorate={readOnly ? undefined : decorate}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={readOnly ? "" : placeholder}
            onBlur={onBlur}
            dir={languageDir}
            onKeyDown={event => {
              if (readOnly) return

              onKeyDown?.(event)

              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault()
                  const mark = HOTKEYS[hotkey]
                  toggleMark(innerEditor, mark)
                }
              }

              if (event.key === "Tab") {
                event.preventDefault()
                const [block] = matchBlockElements(innerEditor, LIST_TYPES)

                if (block) {
                  const [node] = block

                  return event.shiftKey
                    ? Transforms.unwrapNodes(innerEditor, {
                        match: node => LIST_TYPES.includes(node.type),
                        split: true,
                      })
                    : Transforms.wrapNodes(innerEditor, node)
                }

                return innerEditor.insertText("   ")
              }

              if (event.key === "Backspace" || event.key === "Delete") {
                if (innerEditor?.selection) {
                  const { selection } = innerEditor
                  const [block] = matchBlockElements(innerEditor, ["block-quote", "block-code"])

                  if (block && selection.focus.offset === 0 && selection.anchor.offset === 0) {
                    const [node] = block

                    if (node && node.children?.length === 1) {
                      event.preventDefault()
                      toggleBlock(innerEditor, node?.type)
                    }
                  }
                }
              }

              if (["ArrowUp", "ArrowDown"].includes(event.key)) {
                event.stopPropagation()
              }

              if (event.key === "Enter") {
                if (event.shiftKey) {
                  event.preventDefault()

                  if (innerEditor?.selection) {
                    const [shouldInsertNode] = matchBlockElements(innerEditor, ["list-item"])

                    if (shouldInsertNode) {
                      return Transforms.insertNodes(innerEditor, "")
                    }
                  }

                  return innerEditor.insertText("\n")
                }

                if (innerEditor?.selection) {
                  const { selection } = innerEditor

                  const [block] = matchBlockElements(innerEditor, ["image", "block-quote", "block-code", "list-item"])

                  if (block) {
                    const [node] = block

                    if (node.type === "image") {
                      event.preventDefault()
                      return insertDefaultBlock(innerEditor)
                    }

                    if (selection.focus.offset === 0 && selection.anchor.offset === 0) {
                      if (node && node.children?.length === 1 && node.children[0]?.text === "") {
                        event.preventDefault()

                        toggleBlock(innerEditor, node?.type)
                      }
                    }
                  }
                }
              }
            }}
            onDragStart={onDragStart}
          />
          {customPortalRenderer}
        </EditorContainer>
      </Section>
    </Slate>
  )
}

export default RichTextEditor
