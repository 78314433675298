import { useMemo } from "react"
import { reject } from "ramda"
import { getTextSize, sizer } from "@ninjaone/utils"
import { Attachment } from "js/includes/components/Attachment"
import { Box } from "js/includes/components/Styled"
import Indicator from "js/includes/components/Indicator"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export const rejectInlineAttachments = reject(attachment => attachment.metadata.inline)

const customIcons = {
  PROCESSING: () => (
    <Indicator
      loadingToken={localizationKey("Scanning")}
      className="display-flex flex-full align-items-center justify-content-center flex-column overflow-hidden"
      loadingTokenStyleOverrides={{
        marginLeft: sizer(1),
        marginRight: sizer(1),
        fontSize: getTextSize("xs"),
      }}
    />
  ),
}

export const TicketNonInlineAttachments = ({
  attachments = [],
  columns = 3,
  marginTop = 0,
  marginBottom = 0,
  paddingLeft = 0,
}) => {
  const nonInlineAttachments = useMemo(() => rejectInlineAttachments(attachments), [attachments])

  return nonInlineAttachments.length > 0 ? (
    <Box
      display="grid"
      gridArea="attachments"
      gridGap={sizer(2)}
      gridTemplateColumns={`repeat(${columns}, minmax(0, 1fr))`}
      marginTop={marginTop}
      marginBottom={marginBottom}
      paddingLeft={paddingLeft}
    >
      {nonInlineAttachments.map(attachment => (
        <Attachment {...{ key: attachment.id, attachment, customIcons }} />
      ))}
    </Box>
  ) : null
}
