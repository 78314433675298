import { getReadableBytes } from "js/includes/common/_conversions"
import { date, time, localized, localizationKey } from "js/includes/common/utils"

export const collisionHandlingOptionValues = ["SKIP", "OVERWRITE", "OVERWRITE_OLDER"]

const collisionHandlingOptionValuesMapper = {
  SKIP: localizationKey("Skip"),
  OVERWRITE: localizationKey("Overwrite"),
  OVERWRITE_OLDER: localizationKey("Overwrite current if older"),
}

export const defaultOption = {
  label: "",
  value: "",
}

export const createRevisionLabel = ({ created, size }) => `${date(created)} ${time(created)} ${getReadableBytes(size)}`

export const createRevisionOption = ({ created, size, id: value }) => ({
  label: createRevisionLabel({ created, size }),
  value,
})

export const createJobOption = ({ created, totalFiles, totalBytes, jobId: value }) => ({
  label: `${date(created)} ${time(created)}`,
  value,
})

export const createCollisionHandlingOption = value => ({
  label: localized(collisionHandlingOptionValuesMapper[value] ?? value),
  value,
})
