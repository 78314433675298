import React, { useRef, useEffect } from "react"
import { useSlate, useFocused } from "slate-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Editor, Transforms, Text, Range } from "slate"
import { css } from "@emotion/css"
import { Button, Menu, Portal } from "./components"
import { faBold, faCode, faItalic, faStrikethrough, faUnderline } from "@fortawesome/pro-solid-svg-icons"
import { AddLinkButton, RemoveLinkButton } from "./plugins/withInlines"

const toggleFormat = (editor, format) => {
  const isActive = isFormatActive(editor, format)
  Transforms.setNodes(editor, { [format]: isActive ? null : true }, { match: Text.isText, split: true })
}

const isFormatActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n[format] === true,
    mode: "all",
  })
  return !!match
}

const HoveringToolbar = ({ allowLinks }) => {
  const ref = useRef(null)
  const editor = useSlate()
  const inFocus = useFocused()

  useEffect(() => {
    const el = ref.current
    const { selection } = editor

    if (!el) {
      return
    }

    if (!selection || !inFocus || Range.isCollapsed(selection) || Editor.string(editor, selection) === "") {
      el.removeAttribute("style")
      return
    }

    const domSelection = window.getSelection()
    const domRange = domSelection.getRangeAt(0)
    const rect = domRange.getBoundingClientRect()
    el.style.opacity = "1"
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`
    el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`
  })

  return (
    <Portal>
      <Menu
        ref={ref}
        className={css`
          opacity: 0;
          position: absolute;
          z-index: 2100;
          top: -10000px;
          left: -10000px;
          margin-top: -6px;
          transition: opacity 0.75s;
        `}
        onMouseDown={e => {
          // prevent toolbar from taking focus away from editor
          e.preventDefault()
        }}
      >
        <FormatButton format="bold" icon={faBold} />
        <FormatButton format="italic" icon={faItalic} />
        <FormatButton format="underline" icon={faUnderline} />
        <FormatButton format="strikethrough" icon={faStrikethrough} />
        <FormatButton format="code" icon={faCode} />

        {allowLinks && <AddLinkButton />}
        {allowLinks && <RemoveLinkButton />}
      </Menu>
    </Portal>
  )
}

const FormatButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button active={isFormatActive(editor, format)} onClick={() => toggleFormat(editor, format)}>
      <FontAwesomeIcon icon={icon} />
    </Button>
  )
}

export default HoveringToolbar
