import { useCallback, useMemo } from "react"
import { compose } from "ramda"
import { createEditor, Editor, Transforms } from "slate"
import { withHistory } from "slate-history"
import { withReact } from "slate-react"

import {
  withDeleteEmptyBlock,
  withHtml,
  withImages,
  withInlines,
  withMentions,
  withTables,
  withTrailingBlock,
} from "js/includes/components/RichTextEditor/plugins"
import { deserialize } from "js/includes/components/RichTextEditor/serializer"
import { insertDefaultBlock } from "js/includes/components/RichTextEditor/utils"

export function useEditor({ editor, addInlineImage } = {}) {
  const innerEditor = useMemo(
    () =>
      editor ??
      compose(
        withDeleteEmptyBlock,
        withTrailingBlock,
        withTables,
        withImages({
          addInlineImage,
        }),
        withHtml,
        withInlines,
        withMentions,
        withHistory,
        withReact,
      )(createEditor()),
    [editor, addInlineImage],
  )

  const editorReset = useCallback(() => {
    insertDefaultBlock(innerEditor)
    Transforms.delete(innerEditor, {
      at: {
        anchor: Editor.start(innerEditor, []),
        focus: Editor.end(innerEditor, []),
      },
    })
    Transforms.unwrapNodes(innerEditor, { mode: "all" })
  }, [innerEditor])

  const editorInsertText = useCallback(
    text => {
      innerEditor.insertText(text)
    },
    [innerEditor],
  )

  const editorInsertImage = useCallback(
    ({ uri, url, width, height, attachment }) => {
      const text = { text: "" }
      const image = {
        type: "image",
        uri,
        url,
        width,
        height,
        attachment,
        children: [text],
      }

      Transforms.insertNodes(innerEditor, image, { mode: "highest" })
    },
    [innerEditor],
  )

  const editorInsertHtml = useCallback(
    (html, attachments) => {
      editorReset()

      const parsedHtml = new DOMParser().parseFromString(html, "text/html")
      const nodes = deserialize({
        element: parsedHtml?.body ?? document.implementation.createHTMLDocument("").body,
        attachments,
      })

      Transforms.removeNodes(innerEditor, { at: [0] })
      Transforms.insertNodes(innerEditor, nodes, { at: [innerEditor.children.length] })
    },
    [innerEditor, editorReset],
  )

  return [innerEditor, { editorReset, editorInsertText, editorInsertImage, editorInsertHtml }]
}
