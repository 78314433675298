export const theme = {
  blockCursor: "editor-block-cursor",
  ltr: "editor-ltr",
  rtl: "editor-rtl",
  code: "editor-code",
  paragraph: "editor-paragraph",
  quote: "editor-quote",
  text: {
    code: "editor-text-code",
    bold: "editor-text-bold",
    italic: "editor-text-italic",
    strikethrough: "editor-text-strikethrough",
    subscript: "editor-text-subscript",
    superscript: "editor-text-superscript",
    underline: "editor-text-underline",
    underlineStrikethrough: "editor-text-underline-strikethrough",
  },
  codeHighlight: {
    atrule: "editor-token-attr",
    attr: "editor-token-attr",
    boolean: "editor-token-property",
    builtin: "editor-token-selector",
    cdata: "editor-token-comment",
    char: "editor-token-selector",
    class: "editor-token-function",
    "class-name": "editor-token-function",
    comment: "editor-token-comment",
    constant: "editor-token-property",
    deleted: "editor-token-property",
    doctype: "editor-token-comment",
    entity: "editor-token-operator",
    function: "editor-token-function",
    important: "editor-token-variable",
    inserted: "editor-token-selector",
    keyword: "editor-token-attr",
    namespace: "editor-token-variable",
    number: "editor-token-property",
    operator: "editor-token-operator",
    prolog: "editor-token-comment",
    property: "editor-token-property",
    punctuation: "editor-token-punctuation",
    regex: "editor-token-variable",
    selector: "editor-token-selector",
    string: "editor-token-selector",
    symbol: "editor-token-property",
    tag: "editor-token-property",
    url: "editor-token-operator",
    variable: "editor-token-variable",
  },
  image: "editor-image",
  table: "editor-table",
  tableCell: "editor-table-cell",
  tableCellHeader: "editor-table-cell-header",
  tableSelection: "editor-table-selection",
  list: {
    listitem: "editor-list-item",
    nested: {
      listitem: "editor-nested-list-item",
    },
    olDepth: ["editor-ol1", "editor-ol2", "editor-ol3", "editor-ol4", "editor-ol5"],
    ul: "editor-ul",
  },
}
